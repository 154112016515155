<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div class="top" @click="goBackHome">
        <span>{{sysTitle}}后台管理系统</span>
      </div>
      <div>
        <span style="margin-right: 20px;">{{username}}</span>
        <el-button type="info" @click="logout">退出</el-button>
      </div>
      
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <!-- 这里动态绑定 W  如果T 也就是折叠就变成 64px
      如果 F 就是不折叠也就是 200PX -->
      <el-aside
        :width="isCollapse ? '64px' : '200px'"
        style="transition: all 0.3s"
      >
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409EFF"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="activePath"
        >
          <!-- 商品管理 -->
          <el-submenu v-for="(item, index) in menulist" :key="index" :index="item.key">
            <!-- 模块区域 -->
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{item.title}}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item  v-for="(menu, idx) in item.childs" :key="idx" :index="menu.key">
              <template slot="title">
                <i :class="menu.icon"></i>
                <span>{{menu.title}}</span>
              </template>
            </el-menu-item>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <!-- 右侧内容主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      sysTitle: '',
      menulist: [],
      isCollapse: false,
      activePath: '',
      username: ''
    }
  },
  created() {
    let {type, title} = setSysInfo();
    this.sysTitle = title;
    this.activePath = window.sessionStorage.getItem('activePath')
    // type = 'selldata'
    //数据看板单独渲染菜单
    if(type === 'selldata'){
      let userMenus = JSON.parse(window.localStorage.getItem('data-menus'));
      
      const menusMap = {
        "数据看板": {
          key: 'databoard', icon: 'el-icon-s-custom', title: '数据看板',
          childs: [
            {key: 'dataall', icon: 'el-icon-menu', title: '数据看板'},
          ]
        },
        "账号管理": {
          key: 'datauser', icon: 'el-icon-s-custom', title: '账号管理',
          childs: [
            {key: 'datauser', icon: 'el-icon-menu', title: '账号管理'},
          ]
        }
      }
      this.menulist = userMenus.map(o => {
        return menusMap[o]
      })

      return;
    }



    
    let isAdmin = true;
    const userInfo = window.getUserInfo();
    this.username = userInfo ? userInfo.nickName : '';
    if(userInfo && userInfo.role > 1){
      isAdmin = false;
    }

    let userMenus = window.localStorage.getItem('userMenus');

    const goodsMenu = {
          key: 'goods', icon: 'el-icon-s-goods', title: '商品管理',
          childs: [
            {key: 'goods', icon: 'el-icon-menu', title: '商品列表'},
            // {key: 'purchase', icon: 'el-icon-menu', title: '采购价管理'},
            {key: 'groups', icon: 'el-icon-menu', title: '商品组合'},
            {key: 'limits', icon: 'el-icon-menu', title: '商品限购'},
            {key: 'cate', icon: 'el-icon-menu', title: '商品分类'},
          ]
        }
      if(window.hasChannelUI()){
        goodsMenu.childs.push({key: 'channel', icon: 'el-icon-menu', title: '渠道管理'})
      }

      const otherMenu = {
          key: 'ohter', icon: 'el-icon-s-tools', title: '其他设置',
          childs: [
            {key: 'welcome', icon: 'el-icon-menu', title: '首页大图设置'},
            {key: 'welfare', icon: 'el-icon-menu', title: '领券页设置'},
            {key: 'sign', icon: 'el-icon-menu', title: '签到兑换'},
            {key: 'expressTemplate', icon: 'el-icon-menu', title: '快递模板'},
            {key: 'broadcast', icon: 'el-icon-menu', title: '系统广播'},
            {key: 'sms', icon: 'el-icon-menu', title: '短信推送'},
            {key: 'company', icon: 'el-icon-menu', title: '物流公司'},
            {key: 'refundAddress', icon: 'el-icon-menu', title: '退货地址管理'},
            {key: 'vipcard', icon: 'el-icon-menu', title: '会员卡管理'},
          ]
      }
      
      const allMenus = [
        goodsMenu,
        {
          key: 'order', icon: 'el-icon-s-order', title: '订单管理',
          childs: [
            {key: 'order', icon: 'el-icon-menu', title: '订单列表'}
          ]
        },
        {
          key: 'afterSale', icon: 'el-icon-s-order', title: '售后管理',
          childs: [
            {key: 'afterSale', icon: 'el-icon-menu', title: '售后列表'},
            {key: 'feedback', icon: 'el-icon-menu', title: '投诉管理'},
          ]
        },
        {
          key: 'comment', icon: 'el-icon-s-custom', title: '评价管理',
          childs: [
            {key: 'commentGoods', icon: 'el-icon-menu', title: '商品关联'},
            {key: 'comment', icon: 'el-icon-menu', title: '评价管理'},
          ]
        },
        {
          key: 'user', icon: 'el-icon-s-custom', title: '账号管理',
          childs: [
            {key: 'user', icon: 'el-icon-menu', title: '账号管理'},
            {key: 'role', icon: 'el-icon-menu', title: '角色管理'},
          ]
        },
        {
          key: 'databoard', icon: 'el-icon-s-custom', title: '数据看板',
          childs: [
            // {key: 'dataall', icon: 'el-icon-menu', title: '数据看板'},
            {key: 'databoard', icon: 'el-icon-menu', title: '订单数据'},
            {key: 'launch', icon: 'el-icon-menu', title: '投放管理'},
          ]
        },
        otherMenu
      ]

    if(userMenus && userMenus != 'undefined' && !isAdmin){
      userMenus = JSON.parse(userMenus)
      this.menulist = allMenus.filter(menu => {
        menu.childs = menu.childs.filter(o => {
          if(userMenus.find(e => e.name == o.title)){
            return true;
          }
          return false;
        })
        return menu.childs.length > 0;
      })
    }else{
      this.menulist = allMenus;
    }
    
  },

  methods: {
    logout() {
      window.localStorage.clear()
      this.$router.push('/')
    },

    goBackHome() {
      this.$router.push('/Home').catch((err) => {})
    },
    
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
  },
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;
  // 解决对不齐的效果
  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  // 让三个 | 有间距
  letter-spacing: 0.2em;
  // 鼠标经过的时候变成小手
  cursor: pointer;
}

.top {
  cursor: pointer;
}
</style>
